<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}会员`"
      :visible.sync="show"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="60%">
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-row>
          <el-col :span="18">
            <el-row :gutter="16">
              <el-col :span="12">
                <el-form-item :label="$t('memberName')" prop="memberName">
                  <el-input clearable v-model="form.memberName" :maxlength="10" placeholder="请输入会员姓名，最多10字"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('phoneNum')" prop="telephone">
                  <el-input clearable v-model="form.telephone" :disabled="isEdit" placeholder="请输入联系电话"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('idCard')" prop="idCardNo">
                  <el-input clearable v-model="form.idCardNo" type="text" placeholder="请输入身份证号" maxlength="18"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input clearable v-model="form.remark" type="textarea" placeholder="请输入备注" :rows="3" maxlength="250"></el-input>
            </el-form-item>
          </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row>
              <el-col :span="24">
                <el-form-item prop="faceImgUrl" class="coverPic">
                  <el-upload
                  class="upload"
                  v-if="showPic"
                  :show-file-list="false"
                  :with-credentials="true"
                  :action="apis.ImageUpload"
                  :httpRequest="uploadHttpDefault"
                  :headers="{
                    token: TOKEN
                  }"
                  name="file"
                  accept=".png, .jpg"
                  :limit="1"
                  :before-upload="beforeUpload"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  >
                  <img class="image-upload" src="../../../../assets/images/upload_image.png" v-if="!form.faceImgUrl" alt="" srcset="">
                  <img v-if="form.faceImgUrl" :src="form.faceImgUrl" alt srcset width="100" height="100" />
                  <div class="upload-images-tip">
                      建议尺寸690x388px
                  </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'
import { debounce } from "lodash";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule'), // 实例化一个表单的规则
      apis,
      showPic: true,
      prepaidCardDTOList: [], // 充值金额列表
      memberBox: [],
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX', "TOKEN", "cardList", "merchantAccountDetailInfoDTO"]),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      this.form = new AddDialogClass('form')
      console.log('我初始化了++++新增/编辑++++++hahaha')
      console.log("++++++++this.form:", this.form)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.form = data
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent: debounce(function () {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          //编辑
          if (this.isEdit) {
            const params = { ...this.form }
            params.avatar = params.faceImgUrl
            this.$http.post(apis.updateMember, params).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog()
              }
            })
          } else {
            //新增
            const params = { ...this.form }
            params.merchantId = this.merchantAccountDetailInfoDTO.merchantId
            params.merchantName = this.merchantAccountDetailInfoDTO.merchantName
            params.avatar = params.faceImgUrl
            this.$http.post(apis.addMemberInfo, params).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog()
              }
            })
          }
        }
      })
    }, 500),
    beforeUpload(file) {
      // 格式校验
      const isFormat = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';
      // 校验图片大小
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.warning("上传图片大小不能超过2MB");
      }
      if (!isFormat) {
        this.$message.warning("上传的图片类型错误");
      }
    },
    // 封面图片成功
    uploadSuccess(res) {
      this.form.faceImgUrl = res.data.url
      // this.form.coverPic = res.data.path
      this.showPic = false
      this.$nextTick(() => {
        this.showPic = true
      })
      this.$refs.form.validate('faceImgUrl');
    },
    // 封面图片失败
    uploadError(res) {
      console.log(res);
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.period {
  padding-left: 0px !important;
  .right-time-wrapper {
    padding: 0px !important;
    label {
      width: 10px !important;
    }
    .el-form-item__content {
      margin-left: 12px !important;
    }
    .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
      content: none
    }
  }
}
.el-date-editor.el-input {
  width: 100%;
}
.coverPic {
  >div{
    margin-left: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    .upload {
      margin-top: 30px;
      .el-upload {
        img {
          margin-left: 12px;
        }
      }
    }
  }
}

</style>
