/*
 * @Author: zhongtao
 * @LastEditors: zhongtao
 */
// import zh from '@/locales/zh.json'
// import en from '@/locales/en.json'

// const Lang = {
//   zh,
//   en,
// }
/* eslint-disable */
class searchFrom {
  validatePhone = (rule, value, callback) => {
    var phone = value && value.replace(/\s/g, "");//去除空格
    //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
    let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
    let reg = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/;
    if(!value){
        callback([new Error('请输入联系电话')]);
    } else {
      if(!regs.test(phone)&&!reg.test(phone)){
          callback([new Error('电话输入不正确')]);
      } else {
          callback();
      }
    }
  }
  constructor(type) {
    if (type === 'form') {
      this.memberName = '' // 会员姓名
      this.telephone = '' // 联系电话
      this.idCardNo = '' // 身份证号
      this.faceImgUrl = ''
      this.remark = ''
    } else if (type === 'rule') {
      this.memberName = [
        {
          required: true,
          message: "请输入会员姓名",
          trigger: 'blur',
        },
      ]
      this.telephone = [
        { required: true, message: '请输入联系电话', trigger: ['blur'] },
        { min: 11, max: 11, message: '长度在11个字符', trigger: 'blur' }
      ]
      // this.idCardNo = [
      //   {
      //     required: false,
      //     message: "请输入身份证号码",
      //     trigger: ['blur', 'change'] ,
      //   },
      //   {
      //     pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
      //     message: '证件号码格式有误！',
      //     trigger: 'blur'
      //   }
      // ]
      this.linePayType = [
        {
          required: true,
          message: '请选择支付方式',
          trigger: ['change', 'blur'],
        },
      ]
      // this.faceImgUrl = [
      //   {
      //     required: true,
      //     message: "请上传头像",
      //     trigger: 'blur',
      //   },
      // ]
    }
  }
}
export default searchFrom
