<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
  <div class="dialog-container">
    <el-dialog
      :title="type === '3' ? '修改储值卡' : '修改期限卡'"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="60%">
      <div>
        <el-form ref="form" :model="form" label-width="100px" :rules="rules">
          <el-row>
            <el-col :span="12">
              <el-form-item label="会员姓名" prop="memberName">
                <el-input v-model="form.memberName" disabled maxlength="36"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="telephone">
                <el-input v-model="form.telephone" disabled maxlength="20" placeholder="请输入手机号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员卡" prop="cardCategoryId">
                <el-select v-model="form.cardCategoryId" disabled placeholder="请选择" style="width:100%">
                  <el-option v-for="item in memberBox" :key="item.id" :label="item.cardName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="卡号" prop="cardNo" >
                <el-input v-model="form.cardNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" prop="cardStatus">
                <el-select v-model="form.cardStatus" placeholder="请选择" style="width:100%">
                  <el-option v-for="item in statusBox" :key="item.type" :label="item.value" :value="item.type"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item v-if="type === '3'" label="金额" prop="remainAmount">
                <el-input-number v-model="form.remainAmount" :precision="2" :min="0" :max="9999999.99" style="width: 100%"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开卡日期" prop="activateTime">
                <el-date-picker v-model="form.activateTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="截止日期" prop="expirationTime">
                <el-date-picker v-model="form.expirationTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        memberName: '',
        telephone: '',
        cardStatus: '',
        remainAmount: '',
        activateTime: '',
        expirationTime: '',
        cardCategoryId: '',
        cardNo: '',
      }, // 实例化一个表单的变量
      rules: {
        memberName: [{ required: true, message: '请输入会员姓名', trigger: 'blur' }],
        telephone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        cardCategoryId: [{ required: true, message: '请选择会员卡', trigger: 'change' }],
        cardNo: [{ required: true, message: '请输入卡号', trigger: 'blur' }],
        cardStatus: [{ required: true, message: '请选择状态', trigger: 'change' }],
        remainAmount: [{ required: true, message: '请输入金额', trigger: 'blur' }],
        activateTime: [{ required: true, message: '请选择开卡日期', trigger: 'change' }],
        expirationTime: [{ required: true, message: '请选择截止名日期', trigger: 'change' }],
      }, // 实例化一个表单的规则
      statusBox: [],
      memberBox: [],
      type: '',
    }
  },
  // mounted() {
  //   this.$http.get(apis.getAllCardStatus).then((res) => {
  //     if (res.data.code === 0) {
  //       this.statusBox = res.data.data
  //     }
  //   })
  // },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 打开弹窗调用的方法,一般是不用写
     */
    openDialog() {
      this.$http.get(apis.findAllCardIdAndNameForDDL).then((res) => {
        if (res.data.code === 0) {
          this.memberBox = res.data.rows
        }
      })
      this.$http.get(apis.getAllCardStatus).then((res) => {
        if (res.data.code === 0) {
          this.statusBox = res.data.data
        }
      })
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type === '3') {
            this.$http.post(apis.updateCardInfo, this.form).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog()
              }
            })
          } else if (this.type === '1') {
            this.$http.post(apis.memberHoldTimingCardInfoupdateCardInfo, this.form).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog()
              }
            })
          }
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(id, type) {
      // 在这里获取回显的数据
      this.type = type
      if (type === '3') {
        this.$http.get(`${apis.findById}?id=${id}`).then((res) => {
          if (res.data.code === 0) {
            this.form = res.data.data
          }
        })
      } else if (type === '1') {
        this.$http.get(`${apis.memberHoldTimingCardInfofindById}?id=${id}`).then((res) => {
          if (res.data.code === 0) {
            this.form = res.data.data
          }
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.table-container {
  margin-top: 20px;
  width: 100%;
}
.cusomer-head-wrapper {
  display: flex;
  align-items: center;
  .head-item {
    text-align: center;
    flex: 1;
    font-size: 16px;
    font-weight: 400;
  }
  .date {
    flex: 2;
  }
}
.customer-item {
  display: flex;
  align-items: center;
  border: 1px solid #EBEEF5;
  border-bottom: 0;
    padding: 16px 20px;
  .customer-item-detial {
    flex: 1;
    text-align: center;
  }
}
.title{
  border: 1px solid #DCDFE6;
  padding: 16px;
  border-radius: 10px;
  div{
    display: inline-block;
    margin-right: 10px;
    width: 18%;
    /* text-align: center; */
  }
}
.table-title{
  border: 1px solid #DCDFE6;
  padding: 16px 20px;
  div{
    display: inline-block;
    margin-right: 10px;
    width: 15%;
    text-align: center;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
