<template>
  <div class="dialog-container">
    <el-dialog
      title="消费记录"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="60%">
      <el-row type="flex" justify="center" align="center">
        <el-col :span="24" >
          <div class="cusomer-head-wrapper">
            <div class="head-item">会员姓名：{{data.memberName}}</div>
            <div class="head-item">手机号：{{data.telephone}}</div>
            <div class="head-item">{{data.cardName}}</div>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" align="center">
        <div class="table-container">
          <el-table
            :data="tableData"
            style="width: 100%"
            border
            @selection-change="handleSelectionChange">
            <el-table-column
              prop="consumeDatetime"
              align="center"
              label="消费时间">
            </el-table-column>
            <el-table-column
              prop="actuallyPaidByCardPrice"
              align="center"
              label="消费金额">
            </el-table-column>
            <el-table-column
              prop="orderTime"
              align="center"
              label="消费次数">
              <template>
                <span>{{remainTimesFilter()}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="consumeWay"
              align="center"
              label="消费类型">
              <template slot-scope="scope">
                <span>{{scope.row.consumeWay | consumeWayFilter}}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="remark"
              label="备注">
            </el-table-column>
          </el-table>
        </div>
      </el-row>
      <!-- <div class="page-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total">
          </el-pagination>
      </div> -->
      <template slot="footer">
        <!-- <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button> -->
        <el-button class="footer-confirm" @click="closeDialog">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      }, // 实例化一个表单的变量
      rules: '', // 实例化一个表单的规则
      roleData: [], // 角色树状的值
      data: {},
      tableData: [],
      orderDetail: [],
    }
  },
  filters: {
    consumeWayFilter(status) {
      const statusMap = {
        'mp': '小程序',
        'venue': '场馆开单',
        'IOS': '苹果手机APP',
        'Android': '安卓手机',
      }
      return statusMap[status]
    },
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    remainTimesFilter() {
      const statusMap = {
        '1': '1次',
        '2': '1次',
        '3': '',
      }
      return statusMap[this.data.cardType]
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      // this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
          this.$http.post(this.isEdit ? apis.updateCustomer : apis.addCustomer, this.form).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog()
              }
            })
        // }
      // })
      console.log(`我执行了点确定的业务需求`)
    },
    searchData() {
      const data = {
        ...this.data,
        ...this.form,
      }
      this.$http.post(apis.queryCustomerOrderList, data).then((res) => {
        this.data = {
          ...this.data,
          ...{ orderList: res.data.rows },
        }
        this.form.total = res.data.total
        this.data.orderList = res.data.rows
      })
      this.$http.post(apis.queryCustomerDetailInfo, data).then((res) => {
        this.data = {
          ...this.data,
          ...res.data.rows,
        }
      })
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      console.log("++++++++++data:", data)
      this.data.memberName = data.memberName
      this.data.telephone = data.telephone
      this.data.cardName = data.cardName
      this.data.cardType = data.cardType
      // 无卡不能获取消费数据
      if (!data.cardId) return;
      const params = {
        cardId: data.cardId,
        cardType: data.cardType,
      }
      this.$http.post(apis.consumeRecord, params).then((res) => {
        if (res.data.code === 0) {
          console.log("++++++++++res.data:", res.data)
          this.form.total = res.data.total
          this.tableData = res.data.rows
        }
      })
    },
    /**
     * @function 获取全部的角色（树状）
     */
    getRoleData() {
      this.$http.post(apis.User_GetAllRole).then((res) => {
        if (res.data.code === 0) {
          this.roleData = res.data.data.map((item) => {
            const items = { ...item }
            items.key = item.code
            items.label = item.value
            return items
          })
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.table-container {
  margin-top: 20px;
  width: 100%;
}
.cusomer-head-wrapper {
  display: flex;
  align-items: center;
  .head-item {
    text-align: center;
    flex: 1;
    font-size: 16px;
    font-weight: 400;
  }
  .date {
    flex: 2;
  }
}
.customer-item {
  display: flex;
  align-items: center;
  border: 1px solid #EBEEF5;
  border-bottom: 0;
    padding: 16px 20px;
  .customer-item-detial {
    flex: 1;
    text-align: center;
  }
}
.title{
  border: 1px solid #DCDFE6;
  padding: 16px;
  border-radius: 10px;
  div{
    display: inline-block;
    margin-right: 10px;
    width: 18%;
    /* text-align: center; */
  }
}
.table-title{
  border: 1px solid #DCDFE6;
  padding: 16px 20px;
  div{
    display: inline-block;
    margin-right: 10px;
    width: 15%;
    text-align: center;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
